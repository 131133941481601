import React from 'react';
import  Navbar from '../navBar/navbar';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import '../home/home.scss';
import Projects from '../projects/projects';
import Contact from '../Contact/contact';
import memoji from '../../assests/photos/memoji.png'
import Marquee from "../Marquee/marquee.js"
import About from "../About/about.js"
import Footer from "../Footer/footer.js"
import { Link } from 'react-router-dom';
import phyllis from '../../assests/photos/phyllisR.png'
export default function home() {
  return (
    <section className='home'>
      <div className='home__container'>
        <div className='home__name'>
          <h1 className='home__name--first'>Phyllis</h1>
          <h1 className='home__name--second'>Kodua</h1>
        </div>
        <div className='home__photo'>
        <div class='home__photo--overlay'></div>
          <img className='home__photo--img' src={phyllis} alt='phyllis'/>
            
          
        </div>

        <div className='home__title'>
          <h2 className='home__title--1'>Crafting Web Wonders With Code</h2>
          
        </div>
      </div>
      

     
     
      
      
      <About/>
      <Projects/>
      <Contact/>
      <Footer/>
    </section>
    
  )
}
