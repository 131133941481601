import React from 'react';
import headshot from "../../assests/photos/phyllis.png";
import '../About/about.scss';
export default function about() {
  return (
    <section className='about' id='about'>


      <div className='about__text'>
        <h3 className='about__header'>About Me</h3>
        <div className='about__container'>
          <p className='about__container--paragraph'>
            Welcome to my digital space! I'm not your typical web
            designer and frontend developer.Initially drawn to
            healthcare, I discovered my true calling in the dynamic
            world of tech. Fueled by curiosity, I delved into
            JavaScript and joined a web development bootcamp,
            uncovering a realm of innovative possibilities. For me,
            web design isn't just a career; it's a calling. I thrive on
            taking challenges and transforming them into real-life
            tech solutions. Now, I'm seeking a dynamic and
            progressive company to maximize my skills and
            contribute to creative projects. If you're looking for a
            passionate problem-solver ready to redefine
            possibilities, let's connect. I'm excited about the
            opportunity to contribute to a team that values
            innovation and relentless pursuit of excellence.</p>
          <div className='about__container--skills'>
                  <h1>SKILLS</h1>
                  <div className='list'>
                    <ul className='list__1'>
                      <li>HTML/CSS</li>
                      <li>JavaScript (ES6+)</li>
                      <li>UI/UX Design</li>
                      <li>Responsive Design</li>
                      <li>Adobe Xd</li>
                      <li>Wordpress</li>
                    </ul>
                    <ul className='list__2'>
                      <li>Version Control</li>
                      <li>React</li>
                      <li>Node.js</li>
                      <li>MySql</li>
                      <li>Figma</li>
                      <li>Wordpress</li>
                    </ul>
                  </div>
                  <button className='skills'>RESUME</button>
          </div>
        </div>

      </div>


    </section>
  )
}
