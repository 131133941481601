
import './App.scss';
import Home from './components/home/home';
import Projects from './components/projects/projects';
import ProjectPage from './components/projectPage/projectPage';
import About from './components/About/about';
import Contact from './components/Contact/contact';
import Navbar from './components/navBar/navbar';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
function App() {
  return (
    <div>



      <BrowserRouter>
        <Navbar />
        <div>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/#projects'  />
            <Route path='/projectPage' />
            <Route path='/#about' />
            <Route path='/#contact'  />
          </Routes>
        </div>
      </BrowserRouter>
    </div>








  );
}

export default App;
