import React,{useRef, useState} from 'react'
import emailjs from '@emailjs/browser';
import '../Contact/contact.scss';

export default function Contact() {
  
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    e.target.reset();

    emailjs.sendForm('service_1pjmz99', 'template_6znzzi4', form.current, 'iexvDTEKoesxhEBE9')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
     
  };

  
  return (
    <form id={'contact'}ref={form} onSubmit={sendEmail} className='contact'>
      <h2 className='contact__title'>CONTACT ME</h2>
      <p className='contact__subtitle'>Have a question or want to work together?</p>
      <div className='contact__name'>
        
        <input className='contact__input'type="text" placeholder='Name' name="user_name"></input>
      </div>
      <div className='contact__email'>
        
        <input className='contact__input'  placeholder="Email"type="email" name="user_email"></input>
      </div>
      <div className='contact__msg' >
        
        <textarea name="message" className='contact__msg-text' placeholder='Message'></textarea>
      </div>
      <input className='contact__button' type="submit" value="Send" />
      

    </form>
  )
}
