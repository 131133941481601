import React from 'react'
import '../projects/projects.scss'
import kaime from '../../assests/photos/kaime.png';
import method from "../../assests/photos/method.png";
import lbl from "../../assests/photos/LBL.png";
import sika from "../../assests/photos/sika.png";

export default function projects() {
  return (

    <section className='projects' id="projects" >
      <div className='projects__overlay'>
        <h2 className='projects__title'>Latest Projects</h2>
        <p className='projects__quote'>
          "Creativity doesn't wait for that perfect
          moment. It fashions its own perfect
          moments out of ordinary ones." 
        </p>

        <button className='projects__button'> View My Work</button>

      </div>


    </section >
  )
}
