import React from 'react'
import headshot from "../../assests/photos/phyllis2.jpg";
import {Link} from "react-router-dom"
import github from "../../assests/photos/github.png"
import twitter from "../../assests/photos/twitter.png"
import  linkedin from "../../assests/photos/linkedin.png"
import logo from "../../assests/photos/logo.png"
import '../Footer/footer.scss'
export default function footer() {
  return (
    <section className='footer'>
        < div className='footer__header'>
            < div className='footer__img'>
              <img className='footer__headshot' src={headshot}></img>
            </div >
            < div className='footer__text'>
                <h4 className='footer__name'>Phyllis A. Kodua</h4>
                <p className='footer__position'>Front-End Devoloper | Web Designer </p>
            </ div>
        </ div >
        <div>

        </div>
        <div className='footer__contact'>
            <img className="footer__contact--img" src="https://img.icons8.com/ios-glyphs/30/CEA380/linkedin.png" alt="linkedin"></img>
            <img className="footer__contact--img" src="https://img.icons8.com/ios-glyphs/30/CEA380/twitter--v1.png" alt="twitter--v1"></img>
            <img className="footer__contact--img" src="https://img.icons8.com/ios-glyphs/30/CEA380/github.png" alt="github"></img>
            <img className="footer__contact--img" src="https://img.icons8.com/ios-filled/30/CEA380/facebook-new.png" alt="facebook"></img>
        </div>
        <div className='footer__links'>
            <a>Home</a>
            <a>About</a>
            <a>Projects</a>
            
            
        </div>
       
        
    </section>
  )
}
