import React,{useState} from 'react';
import '../navBar/navBar.scss';
import logo from "../../assests/photos/logo.png"
import Hamburger from 'hamburger-react'
import {Link} from 'react-router-dom';

export default function Navbar() {
    const [isOpen, setOpen] = useState(false)

  return (
    <nav className='nav'>
       
            <Link to='/' className='nav__logo--link'><h2 className="nav__logo--image">PAK</h2></Link>
      
        <ul className={`nav__links ${isOpen ? " nav__links--showMenu" : ""}`}>
            <li className='nav__list'>
                <a className='nav__link'href='#about'> About</a>
            </li>
            <li className='nav__list'>
                <a className='nav__link' href='#projects'>Projects</a>
            </li>
            <li className='nav__list'>
                <a className='nav__link' href='#contact' >Contact</a>
            </li>
        </ul>
        
        <Hamburger  rounded color="#AE896C " size={25}toggled={isOpen} toggle={setOpen} />

        
    </nav>
  )
}
